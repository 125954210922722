import React, { useState } from "react";
import { motion } from "framer-motion";
import { SectionWrapper } from "../hoc";
import { styles } from "../styles";
import { projects } from "../constants";
import ReactCardFlip from "react-card-flip";

const ProjectCard = ({
                         name,
                         description,
                         longdescription,
                         tags,
                         image,
                         demo_site,
                         asset_link,
                         source_code_link,
                     }) => {
    const [isFlipped, setIsFlipped] = useState(false);

    const handleClick = () => {
        setIsFlipped((current) => !current);
    };

    return (
        <motion.div className="flex h-[520px] cursor-pointer" onClick={handleClick}>
            <ReactCardFlip isFlipped={isFlipped}>
                {/* Front */}
                <div className="bg-tertiary p-5 rounded-2xl w-[320px] h-[525px] jcblue-gradient cursor-pointer">
                    <div className="relative w-full h-[230px]">
                        <img
                            src={image}
                            alt={name}
                            className="w-full h-full object-cover rounded-md"
                        />
                    </div>
                    <div className="mt-5">
                        <h3 className="text-white font-bold text-[20px]">{name}</h3>
                        <p className="mt-2 text-secondary text-[14px]">{description}</p>
                    </div>
                    <div className="mt-4 flex flex-wrap gap-2">
                        {tags.map((tag) => (
                            <p key={tag.name} className={`text-[14px] ${tag.color}`}>
                                #{tag.name}
                            </p>
                        ))}
                    </div>
                </div>
                {/* Back */}
                <div className="bg-tertiary p-5 rounded-2xl w-[320px] h-[525px] jcblue-gradient cursor-pointer">
                    <div>
                        <h3 className="text-white font-bold text-[24px]">{name}</h3>
                        <p className="mt-2 text-secondary text-[14px]">{longdescription}</p>
                        {demo_site && (
                            <p className="mt-2 text-secondary text-[14px]">
                                <a href={demo_site} target="_blank" rel="noreferrer">
                                    Visit Demo
                                </a>
                            </p>
                        )}
                        {asset_link && (
                            <p className="mt-2 text-secondary text-[14px]">
                                <a href={asset_link} target="_blank" rel="noreferrer">
                                    View Assets
                                </a>
                            </p>
                        )}
                        {source_code_link && (
                            <p className="mt-2 text-secondary text-[14px]">
                                <a href={source_code_link} target="_blank" rel="noreferrer">
                                    View Source Code
                                </a>
                            </p>
                        )}
                    </div>
                </div>
            </ReactCardFlip>
        </motion.div>
    );
};

const Works = () => {
    const [showAll, setShowAll] = useState(false);

    const toggleProjects = () => {
        setShowAll((current) => {
            if (current) {
                // Scroll back to #projects when hiding extended rows
                const projectsSection = document.getElementById("projects");
                if (projectsSection) {
                    projectsSection.scrollIntoView({ behavior: "smooth" });
                }
            }
            return !current;
        });
    };

    // Split projects into rows of 3
    const rows = [];
    for (let i = 0; i < projects.length; i += 3) {
        rows.push(projects.slice(i, i + 3));
    }

    return (
        <div id="projects" className="rounded-[20px] -mt-14">
            <div className={`${styles.padding} min-h-[300px]`}>
                <motion.div>
                    <p className={`${styles.sectionSubText} jcblue-text`}>Results Matter</p>
                    <h2 className={styles.sectionHeadText}>Projects</h2>
                </motion.div>
            </div>
            <div className={`${styles.paddingX} -mt-28 pb-14`}>
                <div className="w-full flex">
                    <motion.p className="mt-1 text-secondary text-[17px] max-w-7xl leading-[30px]">
                        <p>
                            The following projects are a few of my favorites and showcase my
                            skills and experience across a wide range of industries,
                            disciplines, and roles. Each project includes a brief description
                            and the technologies that were selected.
                        </p>
                        <br />
                        For a more up-to-date and complete list of projects, please see my{" "}
                        <u>
                            <a
                                rel="noreferrer"
                                className="cursor-pointer hover:text-white"
                                target="_blank"
                                href="https://www.linkedin.com/in/jcharbneau/details/projects/"
                            >
                                Projects Listing
                            </a>
                        </u>{" "}
                        on LinkedIn.
                        <br />
                        <br />
                        <span className="text-center">
              <p>Click the card to see more details!</p>
            </span>
                    </motion.p>
                </div>
                <div className="mt-4 flex flex-col gap-7">
                    {rows.map((row, rowIndex) => (
                        <motion.div
                            key={`row-${rowIndex}`}
                            className={`flex flex-wrap gap-7 ${
                                !showAll && rowIndex > 1 ? "hidden" : "flex"
                            }`}
                        >
                            {row.map((project, index) => (
                                <ProjectCard key={`project-${index}`} {...project} />
                            ))}
                        </motion.div>
                    ))}
                </div>
                <div className="mt-6 text-center">
                    <button
                        onClick={toggleProjects}
                        className="bg-primary bg-opacity-40 hover:bg-tertiary hover:text-white text-blue-500 font-bold py-2 px-4 rounded"
                    >
                        {showAll ? "Hide" : "More"}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SectionWrapper(Works, "projects");
