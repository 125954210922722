import { Suspense, useRef, useMemo } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial, Preload } from "@react-three/drei";
import * as random from 'maath/random/dist/maath-random.esm';

const Stars = (props) => {
  const ref = useRef();

  // Use useMemo to ensure geometry is computed only once
  const sphere = useMemo(() => random.inSphere(new Float32Array(2000), { radius: 1.2 }), []);

  // Apply rotation in useFrame
  useFrame((state, delta) => {
    if (ref.current) {
      ref.current.rotation.x -= delta / 10;
      ref.current.rotation.y -= delta / 15;
    }
  });

  return (
      <group rotation={[0, 0, Math.PI / 4]}>
        <Points
            ref={ref}
            positions={sphere}
            stride={3}
            frustumCulled
            {...props}
        >
          <PointMaterial
              transparent
              color="#f272c8"
              size={0.002}
              sizeAttenuation
              depthWrite={false}
          />
        </Points>
      </group>
  );
};

const StarsCanvas = () => {
  return (
      <div className="w-full h-auto absolute inset-0 z-[-2] transform-gpu">
        <Canvas camera={{ position: [0, 0, 1] }}>
          <Suspense fallback={null}>
            <Stars />
          </Suspense>
          <Preload all />
        </Canvas>
      </div>
  );
};

export default StarsCanvas;
