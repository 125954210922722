import { BrowserRouter, useParams} from "react-router-dom";
import {About, Contact, Experience, Feedbacks, Hero, Navbar, Works, StarsCanvas, WhatsNext, Hobbies, TopNav} from './components'
import React, {Suspense} from "react";
import {SocialIcon} from "react-social-icons";
import Wheel from "./components/canvas/WheelNav";
import {ColorProvider} from "./components/ColorContext";
import {Family} from "./components";
function App() {
  return (
      <>

          <ColorProvider>
          <BrowserRouter>
              <div className="relative z-0 bg-primary">
                  {/*<TopNav/>*/}

                  <div className="bg-hero-pattern bg-cover bg-no-repeat bg-center">
                      <Navbar/>
                      <Hero/>
                      {/*<Wheel />*/}
                  </div>

                  <div className="relative z-0">
                      <About/>
                      {/*<StarsCanvas/>*/}
                  </div>

                  <div className="relative z-0">
                      <WhatsNext/>
                      <StarsCanvas/>
                  </div>
                  {/*<Family/>*/}
                  <div className="relative z-0">

                      <Experience/>
                      {/*<StarsCanvas/>*/}
                  </div>
                  <div className="relative z-0">

                      <Works/>
                      <StarsCanvas/>
                  </div>
                  <div className="relative z-0">

                      <Feedbacks/>
                      {/*<StarsCanvas/>*/}
                  </div>
                  <div className="relative z-0">

                      <Hobbies/>
                      <StarsCanvas/>
                  </div>
                  <div className="relative z-0">
                      <Contact/>
                      <StarsCanvas/>
                  </div>


              </div>
          </BrowserRouter>
          </ColorProvider>
      </>

  );
}

export default App;
